import React, { useContext } from "react";
import Helmet from "react-helmet";
import Header from "./Header";
import PreHeader from "./PreHeader";
import Footer from "./Footer";
import PostFooter from "./PostFooter";
import BackTopTop from "./BackToTop";
import { FloatingModeContext } from "@components/contexts/FloatingModeContext";
import "@styles/Main.css";

const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://www.iopos.es",
    contactPoint: [
        {
            "@type": "ContactPoint",
            telephone: "+34-644-507-478",
            contactType: "customer support",
            contactOption: "TollFree",
            areaServed: "ES",
            availableLanguage: ["Spanish", "Catalan"],
        },
    ],
};

const Layout = ({ children, location, hideHeader, hideFooter, hideBackToTop }) => {
    const { floatingMode } = useContext(FloatingModeContext);

    return (
        <>
            <Helmet
                bodyAttributes={{
                    className: "font-montserrat",
                }}
            />

            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
            />

            <div>
                {!hideHeader && (
                    <header id="header">
                        <PreHeader location={location} />
                        <Header />
                    </header>
                )}

                <main
                    className="font-montserrat"
                    style={floatingMode && !hideHeader ? { marginTop: "148px" } : null}
                >
                    {children}
                </main>

                {!hideFooter && (
                    <>
                        <Footer />
                        <PostFooter />
                    </>
                )}

                {!hideBackToTop && <BackTopTop />}
            </div>
        </>
    );
};

console.log(`
    ********************************************************************************
    ********************************************************************************
    ********************************************************************************
    ********************************************************************************
    ********************************************************************************
    ********************************************************************************
    ********************************************************************************
    ********************************************************************************
    ********************************************************************************
    ********************************************************************************
    ********************************************************************************
    ********************************************************************************
    ***********,   ***,          .**************************************************
    ************,,,**   ********   ,*,,,**. .,********...,*******,   ,**************
    ************   *.  ,*********   *,    .,.   ,**    ,,    **   ***.,*************
    ************   *,  .********,   *,  .*****.  *.  ,****,  ,*,       *************
    ************   **,   ,*,,**    **,   .*,*.   *,   *,,*   ,*,,****   ************
    ************   *****        ,****,  ,      ******      ****,       *************
    *********************************,  ,*******************************************
    *********************************,..,*******************************************
    ********************************************************************************
    ********************************************************************************
    ********************************************/%%%%%%%%(**************************
    ********************************************/%%%%%%%%(**************************
    ********************************************/%%%%%%%%(**************************
    ************************************#########%%%%%%%%(**************************
    ************************************#########%%%%%%%%(**************************
    ************************************#########%%%%%%%%(**************************
    ************************************#########%%%%%%%%(**************************
    **************************,        .#########%%%%%%%%(**************************
    **************************,        .#########%%%%%%%%(**************************
    **************************,        .#########%%%%%%%%(**************************`);

export default Layout;
